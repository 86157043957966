import React from 'react'
import styled from 'styled-components'
import certTranslationImage from './ic-cert-translation.svg'
import editingImage from './ic-editing.svg'
import localisationImage from './ic-localisation.svg'
import proofreadingImage from './ic-proofreading.svg'
import translationImage from './ic-translation.svg'
import ServicesEng from '../locales/en/Services.json'
import ServicesHr from '../locales/hr/Services.json'

const MainServicesContainer = styled.div`
    margin-top: 60px;
    display: grid;
    max-width: 940px;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-row-gap: 4em;
`

const ServiceContainer = styled.div`
    width: 180px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #545454;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
`

const Caption = styled.div`
    font-family: Montserrat;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #545454;
`

const TranslationIcon = styled.img`
    vertical-align: top;
    width: 80px;
    height: 84px;
    object-fit: contain;
    margin-bottom: 52px;
`

const CertifiedTranslationIcon = styled.img`
    vertical-align: top;
    width: 80px;
    width: 84px;
    height: 100px;
    object-fit: contain;
    word-break: break-word;
    margin-bottom: 35px;
`

const LocalisationIcon = styled.img`
    vertical-align: top;
    width: 80px;
    width: 90px;
    height: 91px;
    object-fit: contain;
    margin-bottom: 43px;
`

const ProofreadingIcon = styled.img`
    vertical-align: top;
    width: 80px;
    width: 74px;
    height: 73px;
    object-fit: contain;
    margin-bottom: 60px;
`

const EditingIcon = styled.img`
    vertical-align: top;
    width: 80px;
    width: 83px;
    height: 80px;
    object-fit: contain;
    margin-bottom: 52px;
`

export default function Services(props) {
    const { language } = props
    let translations = ServicesEng
    if (language === 'hr') {
        translations = ServicesHr
    }
    const t = name => translations[name]

    return (
        <MainServicesContainer itemScope itemType="http://schema.org/Service">
            <ServiceContainer>
                <TranslationIcon src={translationImage} />
                <Caption itemProp="serviceType">{t(`translation`)}</Caption>
            </ServiceContainer>
            <ServiceContainer>
                <CertifiedTranslationIcon src={certTranslationImage} />
                <Caption
                    itemProp="serviceType"
                    style={{ paddingRight: `23px` }}
                >
                    {t(`certtranslation`)}
                </Caption>
            </ServiceContainer>
            <ServiceContainer>
                <LocalisationIcon src={localisationImage} />
                <Caption itemProp="serviceType">{t(`localisation`)}</Caption>
            </ServiceContainer>
            <ServiceContainer>
                <ProofreadingIcon src={proofreadingImage} />
                <Caption itemProp="serviceType">{t(`proofreading`)}</Caption>
            </ServiceContainer>
            <ServiceContainer>
                <EditingIcon src={editingImage} />
                <Caption
                    itemProp="serviceType"
                    style={{ paddingRight: `20px` }}
                >
                    {t(`editing`)}
                </Caption>
            </ServiceContainer>
        </MainServicesContainer>
    )
}

