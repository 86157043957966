import React from 'react'
import styled from 'styled-components'
import ContactDetailsEng from '../locales/en/ContactDetails.json'
import ContactDetailsHr from '../locales/hr/ContactDetails.json'

const ContactContainer = styled.div`
    text-align: center;
    margin-top: 82px;
`

const PhoneAndEmail = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    margin-bottom: 80px;
    margin-left: 25%;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    @media screen and (max-device-width: 375px) {
        width: 100%;
    }
`

const Description = styled.div`
    opacity: 0.6;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin-bottom: 19px;
`

const Name = styled.div`
    height: 40px;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #48c9d1;
    margin-bottom: 14px;
`

const Education = styled.div`
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #222222;
    margin-bottom: 61px;
`

const MobilePhoneDescription = styled.div`
    opacity: 0.6;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin-bottom: 19px;
`

const EmailAdressDescription = styled.div`
    opacity: 0.6;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin-bottom: 19px;
`

const MobileNumber = styled.a`
    height: 40px;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #48c9d1;
    text-decoration: none;
`

const Email = styled.a`
    height: 40px;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #48c9d1;
    text-decoration: none;
`

const RozetaObrt = styled.span`
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #222222;
    padding-right: 32px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-device-width: 375px) {
        padding-right: 0px;
    }
`

const Address = styled.span`
    height: 30px;
    opacity: 0.6;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #3d3d3d;
    padding-right: 6px;
`

const Nikole = styled.span`
    height: 30px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #222222;
`

const Iban = styled.span`
    width: 31px;
    height: 30px;
    opacity: 0.6;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #3d3d3d;
    padding-right: 6px;
`

const IbanNumber = styled.span`
    width: 157px;
    height: 30px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #222222;
    padding-right: 46px;
`

const Oib = styled.span`
    height: 30px;
    opacity: 0.6;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #3d3d3d;
    padding-right: 6px;
`

const OibNumber = styled.span`
    width: 82px;
    height: 30px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #222222;
`
const OibWrapper = styled.b`
    @media screen and (max-device-width: 375px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
`

export default function ContactDetails({ language }) {
    let translations = ContactDetailsEng
    if (language === 'hr') {
        translations = ContactDetailsHr
    }
    const t = name => translations[name]

    return (
        <ContactContainer
            itemScope
            itemType="http://schema.org/ProfessionalService"
        >
            <Description>{t(`youwillbetalking`)}</Description>
            <Name>Tanja Vohalski Adamić</Name>
            <Education>
                {t(`masterenglish`)}
                <br />
                {t(`masterart`)}
            </Education>
            <PhoneAndEmail>
                <div>
                    <MobilePhoneDescription>
                        {t(`mobilephone`)}
                    </MobilePhoneDescription>
                    <MobileNumber href="tel:+385915383110" itemProp="telephone">
                        +385 91 5383 110
                    </MobileNumber>
                </div>
                <div>
                    <EmailAdressDescription>
                        {t(`emailadress`)}
                    </EmailAdressDescription>
                    <Email href="mailto:info@rozeta.hr" itemProp="email">
                        info@rozeta.hr
                    </Email>
                </div>
            </PhoneAndEmail>

            <div>
                <RozetaObrt>
                    <span itemProp="name">Rozeta, obrt za usluge, </span>
                    <span>vl. Tanja Vohalski Adamić </span>
                </RozetaObrt>
                <OibWrapper>
                    <Address>{t(`address`)}</Address>
                    <Nikole itemProp="address">
                        Ulica Nikole Tomašića 10, 10000 Zagreb
                    </Nikole>
                </OibWrapper>
            </div>

            <div>
                <OibWrapper>
                    <Iban>IBAN</Iban>
                    <IbanNumber>HR9823400091160499578</IbanNumber>
                </OibWrapper>
                <OibWrapper>
                    <Oib>OIB</Oib>
                    <OibNumber>85244659864</OibNumber>
                </OibWrapper>
            </div>
        </ContactContainer>
    )
}

