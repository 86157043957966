import React from 'react'
import styled from 'styled-components'
import { Background } from '../components/Background'
import Layout from '../components/Layout'
import Services from './Services'
import TypesOfTexts from './TypesOfTexts'
import ContactDetails from './ContactDetails'
import AboutSection from './AboutSection'
import StartPageEng from '../locales/en/startPage.json'
import StartPageHr from '../locales/hr/startPage.json'

const MainPageContainer = styled.div``

const TitleHero = styled.div`
    max-width: 860px;
    padding-top: 90px;
    font-family: Montserrat;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #48c9d1;
    font-size: 70px;
    @media screen and (max-device-width: 375px) {
        font-size: 56px;
    }
`

const TitleSection1 = styled.div`
    font-family: Montserrat;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin-bottom: 15px;
    margin-top: 100px;
`

const TitleSection2 = styled.div`
    font-family: Montserrat;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin-bottom: 15px;
    margin-top: 100px;
`

const TitleSection3 = styled.div`
    font-family: Montserrat;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin-bottom: 15px;
    margin-top: 100px;
`

const LineSeparator = styled.div`
    width: 111px;
    height: 2px;
    background-color: #48c9d1;
    margin: 0 auto;
`

const TitleSection4 = styled.div`
    font-family: Montserrat;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin-bottom: 15px;
`

const AboutUs = styled.div`
    max-width: 460px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: justify;
    color: #333333;
    margin-left: 262px;
    margin-left: auto;
    margin-right: 0;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 120px;
`

export default function StartPage({ language }) {
    let translations = StartPageEng
    if (language === 'hr') {
        translations = StartPageHr
    }
    const t = name => translations[name]

    return (
        <Layout Background={Background}>
            <MainPageContainer>
                <TitleHero>{t(`title`)}</TitleHero>
                <br />
                <TitleSection1>{t(`services`)}</TitleSection1>
                <LineSeparator />
                <Services language={language} />
                <TitleSection2>{t(`typesoftexts`)}</TitleSection2>
                <LineSeparator />
                <TypesOfTexts language={language} />
                <TitleSection3>{t(`aboutus`)}</TitleSection3>
                <LineSeparator />
                <AboutUs>
                    <AboutSection language={language} />
                </AboutUs>
                <TitleSection4>{t(`contact`)}</TitleSection4>
                <LineSeparator />
                <ContactDetails language={language} />
            </MainPageContainer>
        </Layout>
    )
}

