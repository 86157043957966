import React from 'react'
import styled from 'styled-components'
import TypesOfTextsHr from '../locales/hr/TypesOfTexts.json'
import TypesOfTextsEng from '../locales/en/TypesOfTexts.json'

const GridsContainer = styled.div`
    margin-top: 60px;
    display: grid;
    grid-row-gap: 20px;
`

const TextsGrid1 = styled.ul`
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    @supports (display: grid) {
        display: grid;
        max-width: 940px;
        max-width: 90vw;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }
`

const Rectangle2 = styled.li`
    list-style-type: none;
    @supports (display: grid) {
        height: 120px;
        background-color: #48c9d1;
    }
`

const Rectangle3 = styled.div`
    list-style-type: none;
    @supports (display: grid) {
        height: 120px;
        background-color: #48c9d1;
        max-width: 300px;
    }
    @media screen and (max-device-width: 375px) {
        max-width: 300px;
        max-width: 90vw;
    }
`

const SmallerBoxText = styled.div`
    font-family: Montserrat;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    @supports (display: grid) {
        height: 26px;
        color: #ffffff;
        padding-top: 48px;
        padding-bottom: 46px;
        padding-left: 20px;
        padding-right: 20px;
    }
`

const BiggerBoxText = styled.div`
    font-family: Montserrat;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    @supports (display: grid) {
        height: 52px;
        color: #ffffff;
        padding-top: 35px;
        padding-bottom: 33px;
        padding-left: 60px;
        padding-right: 60px;
    }
`

export default function TypesOfTexts({ language }) {
    let translations = TypesOfTextsEng
    if (language === 'hr') {
        translations = TypesOfTextsHr
    }
    const t = name => translations[name]

    return (
        <GridsContainer>
            <TextsGrid1>
                <Rectangle2>
                    <SmallerBoxText>{t(`legal`)}</SmallerBoxText>
                </Rectangle2>
                <Rectangle2>
                    <SmallerBoxText>{t(`medical`)}</SmallerBoxText>
                </Rectangle2>
                <Rectangle2>
                    <SmallerBoxText>{t(`technical`)}</SmallerBoxText>
                </Rectangle2>
                <Rectangle2>
                    <SmallerBoxText>{t(`marketing`)}</SmallerBoxText>
                </Rectangle2>
            </TextsGrid1>
            <TextsGrid1>
                <Rectangle3>
                    <BiggerBoxText>
                        {t(`artsarchitectureanddesign`)}
                    </BiggerBoxText>
                </Rectangle3>
                <Rectangle3>
                    <BiggerBoxText>{t(`websitesandsoftware`)}</BiggerBoxText>
                </Rectangle3>
                <Rectangle3>
                    <BiggerBoxText>{t(`personaldocuments`)}</BiggerBoxText>
                </Rectangle3>
            </TextsGrid1>
        </GridsContainer>
    )
}

