import React from 'react'
import AboutHr from '../locales/hr/AboutSection.json'
import AboutEng from '../locales/en/AboutSection.json'

export default function AboutSection({ language }) {
    let translations = AboutEng
    if (language === 'hr') {
        translations = AboutHr
    }
    const t = name => translations[name]
    return (
        <div>
            <p>{t(`first`)}</p>
            <p>{t(`second`)}</p>
            <p>{t(`third`)}</p>
        </div>
    )
}
